.topbar {
    min-height: 35px;
    background-color: #f9f9f9;
    .nav {
        text-align: center;
        width: 100%;
        li {
            float: none;
            display: inline-block;
            veritcal-align: top;
            a {
                color: #7f7f7f;
                padding: 6px 10px;
                display: inline-block;
                vertical-align: top;
            }
            &:first-child {
                a {
                    color: $brand-primary;
                }
            }
            &:last-child {
                float: none;
                vertical-align: bottom;
                a {
                    padding: 1px 10px 2px;
                    font-size: 22px;
                    i {
                        color: $brand-primary;
                    }
                }
            }
            &.lang {
                > a {
                    padding-top: 0;
                    padding-bottom: 0;
                    img {
                        display: inline-block;
                        box-shadow: 0 0 1px rgba(0,0,0,.15);
                        margin: 11px 0;
                    }
                }
            }
        }
    }
}

.banner {
    min-height: 90px;
    margin-bottom: 0;
    .brand {
        padding-top: 16px;
        padding-bottom: 15px;
        height: 90px;
        img {
            max-height: 59px;
        }
    }

    .navbar-toggle {
        margin-top: 23px;
        margin-bottom: 22px;
    }

    .navbar-collapse {
        .navbar-nav {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .navbar-nav {
        > li {
            a {
                transition: color .5s;
            }
            &.menu-item-has-children {
                @extend .dropdown;
                &.open {
                    .sub-menu {
                        display: block;
                    }

                    > a {
                        outline: 0;
                        color: $brand-primary;
                        //color: #fff;
                        cursor: default;
                    }
                }
                &.open {
                    &:after {
                        position: absolute;
                        content: " ";
                        width: 0;
                        height: 0;
                        bottom: 0;
                        left: 50%;
                        margin-left: -12px;
                        border-left: 12px solid transparent;
                        border-right: 12px solid transparent;
                        border-bottom: 8px solid #333;
                    }
                }
            }
            .sub-menu {
                @extend .dropdown-menu;
                font-weight: normal;
                background-color: #333;
                color: #fff;
                border: 0;
                margin-top: 0;
                box-shadow: none;
                right: 0;
                left: auto;
                > li > a {
                    color: #fff;
                    font-weight: normal;
                    padding-top: 7px;
                    padding-bottom: 7px;
                }
            }
        }
        @media (max-width: $grid-float-breakpoint-max) {
            > li {
                border-bottom: 1px solid #f5f5f5;
                &:last-child {
                    border-bottom: 0;
                }
                a {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    &:hover,
                    &:focus {
                        background-color: #f9f9f9;
                    }
                }
                &.active {
                    a {
                        background-color: #f9f9f9;
                        &:hover,
                        &:focus {
                            background-color: #f9f9f9;
                        }
                    }
                }
                &.open {
                    a {
                        &:hover,
                        &:focus {
                            background-color: #f9f9f9;
                        }
                    }
                }
            }
            .menu-item-has-children {
                &:after {
                    content: "";
                    display: none;
                }
                .sub-menu {
                    display: block;
                    position: static;
                    float: none;
                    width: auto;
                    margin-top: 0;
                    background-color: transparent;
                    border: 0;
                    box-shadow: none;
                    padding: 0;
                    > li > a,
                    .dropdown-header {
                        padding: 15px 15px 15px 25px;
                        background-color: #fff;
                    }
                    > li > a {
                        color: $navbar-default-link-color;
                        line-height: $line-height-computed;
                        &:before {
                            content: "-\00a0\00a0\00a0";
                        }
                        &:hover,
                        &:focus {
                            background-image: none;
                            background-color: #f9f9f9;
                        }
                    }
                    > li {
                        &.active {
                            > a {
                                color: $brand-primary;
                                background-color: #f9f9f9;
                                &:hover,
                                &:focus {
                                    color: $brand-primary;
                                    background-color: #f9f9f9;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.header-contact-form {
    position: relative;
    background-color: #999;
    color: #f9f9f9;
    //min-height: 500px;
    padding-top: 15px;
    padding-bottom: 30px;
    .form-close {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1000;
        font-size: 20px;
    }
    a {
        color: #fff;
        text-decoration: none;
        transition: color .5s;
        &:hover {
            color: $brand-primary;
        }
    }
    .important {
        display: block;
        font-size: 32px;

        padding: 10px 0;
        margin: 15px 0;
        font-weight: 700;
    }
    input,
    textarea,
    select {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    input,
    select {
        height: 45px;
    }
}

@media (min-width: $screen-sm-min) {

    .topbar {
        .nav {
            width: auto;
            text-align: right;
            li {
                float: left;
                a {
                    color: #7f7f7f;
                    padding: 6px 15px;
                }
                &:first-child {
                    a {
                        color: $brand-primary;
                    }
                }
                &:last-child {
                    float: left;
                    a {
                        padding: 2px 12px;
                        font-size: 22px;
                        i {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }

    .banner {
        min-height: 110px;
        .brand {
            height: 110px;
            padding-top: 16px;
            padding-bottom: 15px;
            img {
                max-height: 79px;
            }
        }

        .navbar-toggle {
            margin-top: 33px;
            margin-bottom: 30px;
        }
    }

    .header-contact-form {
        padding-top: 30px;
        padding-bottom: 45px;
        .important {
            font-size: 38px;
            padding: 15px 0;
            margin: 30px 0;
            border-top: 1px solid #f9f9f9;
            border-bottom: 1px solid #f9f9f9;
        }
    }

}

@media (min-width: $screen-md-min) {

    .banner {
        height: 110px;
        .nav {
            padding-right: 8px;
            > li {
                > a {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
        .navbar-nav {
            > li.menu-item-has-children.open {
                .sub-menu {
                    margin-top: -1px;
                }
            }
        }
    }
    .header-contact-form {
        .important {
            font-size: 46px;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .banner {
        .nav {
            padding-right: 0;
            > li {
                > a {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

}

@media (min-width: 1280px) {
    .topbar {
        position: relative;
        .nav {
            position: static;
            li {
                &:last-child {
                    text-align: right;
                    position: absolute;
                    float: none;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    a {
                        position: static;
                        display: inline-block;
                        text-align: right;
                        padding: 2px 10px;
                        transition: all .5s;
                        &:hover {
                            color: #fff;
                            background-color: $brand-primary;
                            i {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1280px) {
	.navbar-header {
		float: right !important;
	}
}