body {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.row,
.block {
    img {
        @include img-responsive("inline-block");
    }
}

body {
    overflow-x: hidden;
    margin: 0;
}

.block {
    padding-top: 15px;
    padding-bottom: 15px;
}

.block-blue {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: $brand-primary;
}

@mixin center($x: true, $y: true) {
    position: absolute;

    $yPos: 0;
    $xPos: 0;

    @if $x {
        $xPos: -50%;
        left: 50%;
    }

    @if $y {
        top: 50%;
        $yPos: -50%;
    }

    transform: translate($xPos, $yPos);
    transform: translate3d($xPos, $yPos, 0);
}

@media (min-width: $screen-sm-min) {
    .block {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media (min-width: $screen-md-min) {
    .block {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
