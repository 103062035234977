.content-info {

    .footer-top {
        background-color: #545454;
        min-height: 150px;
        color: #f9f9f9;
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 1.6;
        font-weight: 300;
        a {
            color: #fff;
            font-weight: 300;
            &:hover {
                text-decoration: none;
                color: #7a7a7a;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 700;
            margin: 25px 0 25px;
            letter-spacing: 1px;
            color: #fff;
        }
        .btn {
            margin: 10px 0;
            font-size: 16px;
            padding: 10px 15px;
            font-weight: 600;
            &:hover {
                color: #fff;
            }
        }
        .nav-sitemap {
            line-height: 1.2;
            li {
                clear: both;
                margin: 0;
                &:before {
                    content: "\2022";
                    font-size: 22px;
                    display: inline-block;
                    color: #888;
                    padding: 0 10px;
                }
                a {
                    display: inline-block;
                    color: #f9f9f9;
                    font-size: 16px;
                    //text-transform: uppercase;
                    padding: 4px 5px;
                    //letter-spacing: 1px;
                    margin: 0;
                    &:hover {
                        color: #444;
                        background-color: #f9f9f9;
                    }
                    &.icon {
                        float: left;
                        margin: 0 0 10px;
                        padding: 0;
                        &:hover {
                            background-color: transparent;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
        .nav-footer-social {
            li {
                float: left;
                margin: 25px 2px 15px 0;
                a {
                    background-color: #656565;
                    padding: 4px 7px;
                    font-size: 20px;
                    &:hover,
                    &:focus {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .footer-bottom {
        background-color: #4c4c4c;
        color: #7a7a7a;
        font-size: 16px;
        padding-top: 15px;
        padding-bottom: 15px;
        //text-align: center;
        .logo-award {
            img {
                display: block;
                width: 112px;
                height: 112px;
                margin: -75px auto 0;
            }
        }
        @media only screen and (max-width: 990px) {
            .logo-award img {
                margin: -40px auto 0;
            }
        }
        @media only screen and (max-width: 767px) {
            .left {
                width: 80%;
                float: left;
            }
            .right {
                width: 20%;
                float: right;
            }
            .logo-award {
                display: none;
                img {
                    margin: -40px auto 0;
                }
            }
        }
    }
    .footer-home-link {
        font-size: 18px;
        color: #fff;
        &:hover {
            color: $gray-lighter;
        }
    }

    .nav-contact {
        list-style-type: none;
        padding: 0;
        margin-top: 15px;
        li {
            margin: 10px 0;
        }
    }

}

@media (min-width: $screen-sm-min) {
    .content-info {
        .footer-top {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .nav-contact {
            li {
                margin: 0;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .content-info {
        .footer-top {
            padding-top: 60px;
            padding-bottom: 60px;
            .nav-sitemap {
                li {
                    a {
                        letter-spacing: 1px;
                    }
                }
            }
        }
        .footer-bottom {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}