.block-video {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: rgba(0,55,139,.75);
    color: #fff;
    text-align: center;
    h3 {
        font-size: 32px;
    }
    .btn {
        margin-top: 10px;
        font-size: 14px;
        text-transform: uppercase;
        padding: 12px 25px;
        background: transparent;
        border: 2px solid #fff;
    }
}

.videobg {
    position: absolute;
    background-position: center;
    overflow: hidden;
    background-size: cover;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    z-index: -1;
}

.videobg video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 500ms ease-out;
    -webkit-transition: opacity 500ms ease-out;
    -moz-transition: opacity 500ms ease-out;
    margin: 0;
    padding: 0;
}

.videobg video.visible {
    opacity: 1;
}


@media (min-width: $screen-md-min) {

    .block-video {
        padding-top: 120px;
        padding-bottom: 120px;
        h3 {
            font-size: 42px;
        }
    }

}