.map-wrapper {
    .map {
        height: 350px;
        width: 100%;
    }
    img {
        display: inline;
        width: auto;
        height: auto;
        max-width: auto;
        max-height: auto;
    }
}