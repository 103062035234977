.block-technology {
    background-color: #f9f9f9;
    h2 {
        margin: -10px 0 30px;
        text-align: center;
    }
}

.page-template-template-products {
    .content {
        font-size: 18px;
    }
}

.box {
    display: block;
    font-size: 16px;
    &.box-technology {
        padding: 15px 25px 15px;
        h3 {
            font-size: 24px;
            font-weight: 500;
            color: #222;
            margin: 0 0 15px;
        }
        &:hover,
        &:focus {
            text-decoration: none;
        }
        .img-container {
            margin-bottom: 15px;
            position: relative;
            opacity: .7;
            &:after {
                @include center();
                @include fa-icon();
                content: "\f01d";
                color: rgba(255,255,255,.5);
                text-align: center;
                font-size: 60px;
            }
        }
        img {
            display: block;
            width: 100%;
        }
        .box-content {
            position: relative;
            &:before {
                position: absolute;
                @include fa-icon();
                content: "\f00d";
                display: block;
                bottom: 100%;
                left: 0;
                height: 50px;
                width: 100%;
                color: #616267;
                text-align: center;
                font-size: 32px;
                padding: 9px 0;
            }
            margin-top: 65px;
            border-top: 1px solid #eee;
            color: $text-color;
            padding: 15px 0 0;
        }
        &.active {
            background-color: #fff;
            box-shadow: 0 0 1px rgba(0,0,0,.2);
            transition: box-shadow .5s;
            &:hover {
                box-shadow: 0 0 3px rgba(0,0,0,.4);
            }
            .img-container {
                opacity: 1;
            }
            .box-content {
                &:before {
                    content: "\f00c";
                    color: $brand-primary;
                }
            }
        }
    }

    &.box-videos {
        padding: 15px 25px 15px;
        background-color: #fff;
        box-shadow: 0 0 1px rgba(0,0,0,.2);
        transition: box-shadow .5s;
        h3 {
            font-size: 24px;
            font-weight: 500;
            color: #222;
            margin: 0 0 15px;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            box-shadow: 0 0 3px rgba(0,0,0,.4);
        }
        .img-container {
            margin-bottom: 15px;
            position: relative;
            &:after {
                @include center();
                @include fa-icon();
                content: "\f01d";
                color: rgba(255,255,255,.5);
                text-align: center;
                font-size: 60px;
            }
        }
        img {
            display: block;
            width: 100%;
        }
        .box-content {
            position: relative;
            margin-top: 15px;
            border-top: 1px solid #eee;
            color: $text-color;
            padding: 15px 0 0;
        }
    }

    &.box-project {
        position: relative;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: translate3d(0,0,0);
        h3 {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            text-shadow: 0 0 3px #000;
            padding: 20px;
            margin: 0;
            min-height: 50px;
            width: 100%;
            background: linear-gradient(to bottom, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 90%, rgba(0,0,0,0) 100%);
            transform: scale(1);
            z-index: 1000;
        }
        &:hover,
        &:focus {
            text-decoration: none;
        }
        .img-container {
            position: relative;
            overflow: hidden;
            img { transition: all .5s ease-in-out; }
        }
        &:hover .img-container img {
            transform: scale(1.2);
        }
        .box-btn {
            @include center();
            padding: 12px 20px 10px;
            background: rgba(255,255,255,.9);
            box-shadow: 0px 0px 20px 0px rgba(255,255,255,0.3);
            text-transform: uppercase;
            white-space: nowrap;
        }
        img {
            display: block;
            width: 100%;
        }
        .box-content {
            padding: 15px 20px 5px;
            background-color: #f9f9f9;
            border: 1px solid #eee;
            color: #222;
            border-top: 0;
        }
    }

}

.ansprechpartner {
    padding-top: 16px;
    padding-bottom: 10px;
    h3 {
        text-align: center;
        font-weight: normal;
    }
    .content {
        .data-prim {
            padding-top: 15px;
            .zeile {
                margin-bottom: 5px;
            }
        }
        .data-sek {
            padding-top: 15px;
            .zeile {
                margin-bottom: 5px;
                font-weight: bold;
                color: rgb(0, 55, 139);
                .fa {
                    display: inline-block;
                    width: 22px;
                    margin-right: 5px;
                    color: rgb(59, 60, 66);
                }
            }
        }
        .link {
            padding-top: 40px;
        }
        @media only screen and (max-width: 1024px) {
            .img {
                //display: none;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .content {
            text-align: center;
            .pull-left {
                float: none !important;
            }
        }
    }
}
