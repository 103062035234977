.intro-header {
    background: $blue-light url(../images/nordansicht-elbphilharmonie.png) top center no-repeat;
    background-image: -webkit-image-set(url('../images/nordansicht-elbphilharmonie.png') 1x, url('../images/nordansicht-elbphilharmonie.png') 2x);
    background-size: cover;
    min-height: 350px;
    position: relative;

    .caption {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        h1 {
            font-weight: 400;
            font-size: 28px;
            margin-top: 0;
            span {
                display: inline-block;
                background: rgba(255,255,255,.6);
                //line-height: 1.2;
                margin-bottom: 20px;
                padding: 10px 25px;
            }
        }
        .btn {
            font-size: 14px;
            text-transform: uppercase;
            padding: 15px 25px;
        }
    }

    .intro-header-bottom {
        @include clearfix();
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 100px;
        background-color: rgba(0,0,0,0.3);
        li {
            min-height: 100px;
            border-right: 1px solid rgba(255,255,255,.9);
            &:first-child {
                border-left: 1px solid rgba(255,255,255,.9);
            }
        }
        a {
            min-height: 100px;
            color: #fff;
            padding: 5px 10px;
            transition: all .5s;
            &:hover,
            &:focus {
                color: #fff;
                background-color: $brand-primary;
            }
            h3 {
                font-size: 16px;
                margin-top: 10px;
            }
        }
    }
}

.content {
    padding-top: 0;
    padding-bottom: 30px;
}

.img-box {
    display: block;
    color: $text-color;
    text-align: center;
    margin-bottom: 30px;
    &:hover,
    &:focus {
        text-decoration: none;
        opacity: 0.85;
    }
    h3 {
        color: $brand-primary;
        font-size: 16px;
    }

}

.header-image {
    height: 200px;
    background-position: center center;
    background-size: cover;
    position: relative;
    .page-header {
        //@include center("true", "false");
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        h1 {
            font-weight: 400;
            font-size: 28px;
            margin: 20px 0 0;
            text-align: center;
            padding: 0;
            span {
                display: inline-block;
                background: rgba(255,255,255,.6);
                //line-height: 1.2;
                margin-bottom: 20px;
                padding: 10px 25px;
            }
        }
        .btn {
            font-size: 14px;
            text-transform: uppercase;
            padding: 15px 25px;
        }
    }
}

.page-header {
    margin-top: 0;
    margin-bottom: 0;
    h1 {
        font-size: 18px;
    }
}

@media (min-width: $screen-sm-min) {
    .intro-header {
        min-height: 500px;
        .caption {
            margin-top: -60px;
            h1 {
                font-size: 36px;
            }
            .btn {
                font-size: 14px;
                text-transform: uppercase;
                padding: 15px 25px;
            }
        }
    }
    .header-image {
        height: 250px;
        .page-header {
            h1 {
                font-size: 36px;
            }
            .btn {
                font-size: 14px;
                text-transform: uppercase;
                padding: 15px 25px;
            }
        }
    }
    .content {
        padding-top: 15px;
    }
    .page-header {
        h1 {
            font-size: 28px;
        }
    }

}

@media (min-width: $screen-md-min) {
    .intro-header {
        min-height: 600px;
        .caption {
            h1 {
                font-size: 42px;
            }
        }
    }
    .header-image {
        height: 300px;
        .page-header {
            h1 {
                font-size: 42px;
            }
        }
    }
    .content {
        padding-top: 30px;
    }
    .page-header {
        h1 {
            font-size: 36px;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .intro-header {
        min-height: 630px;
    }
    .header-image {
        height: 350px;
    }
}

@media (min-width: 1600px) {
    .header-image {
        height: 400px;
    }
}