h1 {
    font-size: 36px;
    color: $brand-primary;
}

h2 {
    font-size: 28px;
    color: $brand-primary;
}

.content {
    a {
        font-weight: bold;
    }
}